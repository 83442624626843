<template>
  <div>
    <div class="timer bold" v-if="days" v-show="statusType === 'running'">

      {{ days }}Day(s) {{ this.formatValue(hours) }}:{{
        this.formatValue(minutes)
      }}:{{ this.formatValue(seconds) }}
    </div>
    <div class="timer bold" v-else v-show="statusType === 'running'">

      {{ this.formatValue(hours) }}:{{ this.formatValue(minutes) }}:{{
        this.formatValue(seconds)
      }}
    </div>

  </div>
</template>


<script>
import moment from "moment-timezone";
import { getUUIDCtf } from "@/api/ctf.js"
export default {
  name: "timer",
  props: {
    uuid: String
  },
  data: function () {
    return {
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      statusType: ""
    };
  },
  created() {
    this.getTime();

  },
  methods: {
    async getTime() {
      const res = await getUUIDCtf(this.uuid)
      this.start = moment.tz(res.data.data.rectf.start_time, "Asia/Shanghai");
      this.end = moment.tz(res.data.data.rectf.end_time, "Asia/Shanghai");
      this.timerCount(this.start, this.end);
      this.interval = setInterval(() => {
        this.timerCount(this.start, this.end);
      }, 1000);
      this.statusType = localStorage.getItem("statusType")

    },
    formatValue(timeElement) {
      if (timeElement < 10) {
        return "0" + timeElement.toString();
      }
      return timeElement;
    },
    timerCount: function (start, end) {
      // Get current date and time in Asia/Shanghai timezone
      let now = moment.tz("Asia/Shanghai");

      // Find the distance between now and the count down date in milliseconds
      //start.diff(now)
      let distance = start.diff(now);
      let passTime = end.diff(now);

      this.calcTime(passTime);
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
  }
};
</script>
