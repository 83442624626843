<template>
  <div id="auth-layout">
    <div class="navbar">
      <nav v-if="pathLogin()" class="navbar-container">
        <router-link to="/" class="navbar-link">
          <div class="logo">
            <img v-if="this.getLogoUrl()" :src="this.getLogoUrl()" />
          </div>
        </router-link>
        <div class="navbar-registration">
          <!-- <p class="navbar-already-registered">New here?</p> -->
          <router-link to="/register" class="login-link">注册</router-link>
        </div>
      </nav>
      <nav v-else class="navbar-container">
        <router-link to="/" class="navbar-link">
          <div class="logo">
            <img v-if="this.getLogoUrl()" :src="this.getLogoUrl()" />
          </div>
        </router-link>
        <div class="link-container">
          <router-link v-for="routes in getLinks()" v-bind:key="routes.index" :class="{
            'navbar-link': true,
          }" :to="`${routes.page}/`">{{ routes.text }}
          </router-link>
        </div>
        <div class="adminNavDropdown" @mouseover="showDropdownContent(true)" @mouseleave="showDropdownContent(false)">
          <button class="adminNavDropbtn">
            {{ username }}
            <img :src="dropdown" />
          </button>
          <div v-if="displayDropdownContent" class="adminNavDropdownContent">
            <router-link to="/license" class="adminNavDropdownLink">GTools绑定</router-link>
            <router-link to="/resetpassword" class="adminNavDropdownLink">个人信息</router-link>
            <a class="adminNavDropdownLink cursor-pointer" @click="logout()">退出</a>
          </div>
        </div>
        <div class="navbar-registration">
          <!-- <p class="navbar-already-registered">Already Registered?</p> -->
          <router-link to="/login" class="login-link" v-if="!loggedIn()">登录</router-link>
        </div>
      </nav>
    </div>
    <div id="container">
      <slot />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>

import LoginUser from "../api/admin/authAPI.js";
import { dropdown } from "../constants/images";
export default {
  name: "AuthLayout",
  data() {
    return {
      // baseUrl: CONFIG.beastRoot,
      ctf_uuid: "",
      dropdown,
      userInfo: {},
      username: "",
      displayDropdownContent: false,
      baseUrl: "",
      userRole: LoginUser.getUserInfo().role,
      statusType: "",
      userLinks: [
        // {
        //   index: 0,
        //   text: "课堂笔记",
        //   page: "/challenges/",
        // },
        // {
        //   index: 1,
        //   text: "工具下载",
        //   page: "/tools",
        // },

      ],
    };
  },
  components: {
    // IndexNavbar,
  },
  mounted() {
    this.GetUserInfoAndCTFUUID();
  },
  methods: {
    GetUserInfoAndCTFUUID() {
      const user = localStorage.getItem("userInfo");
      if (user !== null) {
        // this.username = user.userName
        this.username = JSON.parse(user).userName
        this.ctf_uuid = user.uuid;
        this.statusType = localStorage.getItem("statusType");
      }
      console.log(this.userInfo)

    },
    getLinks() {
      return this.userLinks;
    },
    logout() {
      LoginUser.logout();
      this.$router.push("/login");
    },
    showDropdownContent(showContent) {
      this.displayDropdownContent = showContent;
    },
    loggedIn() {
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo == null) {
        return false;
      } else {
        return true;
      }
    },
    pathLogin() {
      var route = this.$route.name;
      return route.includes("login");
    },
  },
};
</script>

<style scoped>
#container {
  padding-left: 25%;
  padding-right: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15rem;
}
</style>
