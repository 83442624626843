import axiosInstance from "./axiosInstance.js";

export const StartEnv = (data) => {
    return axiosInstance({
        url: '/ctf_c/startEnv',
        method: 'post',
        data: data,

    })
}

export const StopEnv = (data) => {
    return axiosInstance({
        url: '/ctf_c/stopEnv',
        method: 'post',
        data: data,

    })
}

export const GetEnv = (data) => {
    return axiosInstance({
        url: '/challenges/getEnv',
        method: 'post',
        data: data,

    })
}
