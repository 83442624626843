var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"challengesByTag"},[_c('div',{staticClass:"challengesByTag-tag"},[_vm._v(_vm._s(_vm.tag))]),_c('div',{staticClass:"challengesByTag-challenges"},_vm._l((_vm.challenges),function(chall){return _c('div',{key:chall.id,class:[
      {
        'challengesByTag-challenge': true,
        selected: _vm.selectedChallenge === chall.name,
        disabled: chall.status !== 'Deployed'
      }
    ],on:{"click":function($event){return _vm.emitChallName(chall.name, chall.status)}}},[_c('img',{staticClass:"maintenance-msg",attrs:{"src":require("@/assets/maintenance-msg.svg")}}),_c('div',{staticClass:"challName"},[_c('p',{staticClass:"challengesByTag-challName"},[_vm._v(_vm._s(chall.name)),(chall.category == 'docker')?_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require("@/assets/docker1.svg")}}):_vm._e()]),(chall.isSolved && !(chall.status !== 'Deployed'))?_c('img',{attrs:{"src":require("@/assets/tick.svg")}}):_vm._e(),(chall.status !== 'Deployed')?_c('img',{attrs:{"src":require("@/assets/maintenance.svg")}}):_vm._e()]),_c('p',{staticClass:"challengesByTag-challInfo"},[_vm._v(" "+_vm._s(chall.points)+" 分 ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }