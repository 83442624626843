<template>
 
  <div class="home">
    <div class="event">
      <div class="activity-container">
        <div v-for="activity in ctfData" :key="activity.uuid" class="event-info">
          <div class="event-info-text">
            <div v-if="activity.status == 0" class="tagline">
              {{ activity.name }}(未开始)
            </div>
            <div v-else-if="activity.status == 1" class="tagline">
              {{ activity.name }}(正在进行)
            </div>
            <div v-else class="tagline">{{
              activity.name }}(已结束)</div>
            <div class="description">
              {{ activity.about }}
            </div>
            <div class="description">开始时间</div>
            <div class="timing">
              {{ formatDate(activity.start_time) }}
            </div>
            <div class="description">结束时间</div>
            <div class="timing">
              {{ formatDate(activity.end_time) }}
            </div>
            <router-link style="text-decoration: none" :to="'/challenges/' + activity.uuid">
              <Button text="进入比赛" :onclick="test" class="primary-cta" />
            </router-link>
          </div>
          <div class="event-info-img">
            <img class="landing-img" src="@/assets/landing1.svg" />
          </div>
        </div>
      </div>
      <div class="prize-info">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page" :page-sizes="[2, 4, 6, 8]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCtfList } from "@/api/ctf";
import Button from "../components/Button.vue";
import moment from "moment-timezone";
import LoginUser from "../api/admin/authAPI.js";
export default {
  name: "Index",
  components: {
    Button,
  },
  data() {
    return {
      ctfData: [],
      page: 1,
      pageSize: 2,
      total: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val
      this.getData();
    },
    test() { },
    formatDate(time) {
      if (time !== null && time !== "") {
        var date = new Date(time);
        return this.formatTimeToStr(date, "yyyy-MM-dd HH:mm:ss");
      } else {
        return "";
      }
    },
    formatTimeToStr(datetime, fmt) {
      var o = {
        "M+": datetime.getMonth() + 1,
        "d+": datetime.getDate(),
        "h+": datetime.getHours() % 12 === 0 ? 12 : datetime.getHours() % 12,
        "H+": datetime.getHours(),
        "m+": datetime.getMinutes(),
        "s+": datetime.getSeconds(),
        "q+": Math.floor((datetime.getMonth() + 3) / 3),
        S: datetime.getMilliseconds(),
      };
      var week = {
        0: "\u65e5",
        1: "\u4e00",
        2: "\u4e8c",
        3: "\u4e09",
        4: "\u56db",
        5: "\u4e94",
        6: "\u516d",
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (datetime.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (RegExp.$1.length > 1
            ? RegExp.$1.length > 2
              ? "\u661f\u671f"
              : "\u5468"
            : "") + week[datetime.getDay() + ""]
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    async getData() {
      const res = await getCtfList({ page: this.page, pageSize: this.pageSize });
      this.ctfData = res.data.data.list;
      this.total = res.data.data.total;
    },
    isLoggedIn() {
      let userInfo = LoginUser.getUserInfo();
      if (userInfo) {
        return userInfo.access && userInfo.role === "user";
      }
      return false;
    },
    convertTimeFormat(time) {
      return moment(time, "HH:mm:ss UTC: Z, DD MMMM YYYY, dddd").format(
        "HH:mm:ss UTC: Z, Do MMMM YYYY, dddd"
      );
    },
  },
};
</script>

<style scoped lang="scss">
button {
  margin-top: 1rem;
}
</style>
