<template>
  <div class="stats">
    <div class="rank">
      <p class="text">Rank</p>
      <p class="number">{{ details.score }}</p>
    </div>
    <div class="dynamicStats">
      <p class="number">{{ total }}</p>
      <p class="text">总数</p>
    </div>
    <div class="dynamicStats">
      <p class="number" v-if="details.solves == null">0</p>
      <p class="number" v-else> {{details.solves.length}}</p>
      <p class="text">已解答</p>
    </div>
    <div class="dynamicStats">
      <p class="number" v-if="details.solves == null">{{ total - 0 }}</p>
      <p class="number" v-else>{{ total - details.solves.length }}</p>
      <p class="text">未解答</p>
    </div>
    <img src="@/assets/rank.svg" class="image" />
  </div>
</template>

<script>
export default {
  name: "StatsNavbar",
  props: ["details", "total"],
};
</script>
