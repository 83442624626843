<template>
  <div class="user-lb-container">
    <div class="user-lb-heading">
      <div class="adminHeadingColor">
        <img :src="leaderboard" class="adminHeadingColor" />
        <el-button type="primary" @click="goBack">返回比赛</el-button>
      </div>
    </div>
    <spin-loader v-if="isLoading" />
    <div v-else-if="users.length > 0">
      <!-- <LeaderboardGraph /> -->
      <admin-table v-if="resultQuery.length > 0" :tableCols="tableCols" :rows="resultQuery"
        :links="[{ col: 'username', redirect: '/user/' }]" :maxElementPerPage="10" :userData="userInfo"
        :keys="searchQuery" />
      <div class="adminEmptyDataContainer" v-else>
        <span class="adminEmptyData">No Users</span>
      </div>
    </div>

    <div class="adminEmptyData" v-else>
      <span> 暂无排行数据 </span>
    </div>
  </div>
</template>
<script>
import adminTable from "../components/adminTable.vue";
// import LeaderboardGraph from "@/components/LeaderboardGraph.vue";

import SpinLoader from "../components/spinLoader.vue";
import { tableCols, colors, lineGraphOptions } from "../constants/constants";
import { leaderboard, search } from "../constants/images";
import LoginUser from "../api/admin/authAPI.js";
import { getUUIDRankList } from "@/api/ctf";
import { Backtop } from "element-ui";

export default {
  components: { adminTable, SpinLoader },
  name: "AdminLeaderboard",
  data() {
    return {
      isLoading: false,
      leaderboard,
      search,
      lineColors: colors.lineGraph,
      scoreSeries: [],
      lineGraphOptions: lineGraphOptions(true),
      searchQuery: "",
      tableCols: tableCols.leaderboard,
      users: [],
      displayUsers: [],
      userInfo: {},
      loading: {
        userNotFetched: true,
        usersNotFetched: true,
      },
    };
  },
  created() {
    this.username = LoginUser.getUserInfo().userName;
  },
  methods: {
    goBack(){
      this.$router.go(-1)
      
    },
    async GetRank() {
      const user_uuid = LoginUser.getUserInfo().uuid;
      const uuid = localStorage.getItem("uuid");
      getUUIDRankList(uuid)
        .then((res) => {
          if (res.data.data.list != null) {
            res.data.data.list.forEach((element) => {
              if (element.user_uuid == user_uuid) {
                this.userInfo = {
                  username: element.nickname, //用户名
                  groupname: element.group_name, //单位
                  score: element.score, //分值
                  rank: element.ID, //排名
                  dtime: element.dtime, // 答题用时


                };
              }
              this.users.push({
                rank: element.ID,
                username: element.nickname,
                groupname: element.groupName,
                score: element.score,
                dtime: element.dtime,

              });
            });
            this.displayUsers = this.users;

          }

        })
        .finally(() => {
          this.loading.isLoading = false;
        });
    },
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.displayUsers.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.username.toLowerCase().includes(v));
        });
      } else {
        return this.displayUsers;
      }
    },
  },

  async mounted() {
    await this.GetRank();
  },

  beforeCreate() {
    this.$store.commit("updateCurrentPage", "userLeaderboard");
  },
};
</script>
