<template>
  <div class="auth">
    <div class="auth-container">
      <div class="heading">
        用户注册<label class="switch"></label>
      </div>
      <ErrorBox v-if="msg" :msg="msg" :icon="icon" />
    </div>
    <div class="form-div">
      <div class="form">
        <div class="info">
          <input
            v-model="userName"
            type="text"
            class="adminFormTitle inputField"
            id="name"
            name="name"
            placeholder="用户名*"
            required="true"
            @keyup.enter="register"
          />
        </div>
        <div class="info">
          <input
            v-model="nickName"
            type="text"
            class="adminFormTitle inputField"
            id="username"
            name="user_name"
            placeholder="昵称*"
            required="true"
            @blur="validateUsername"
            @keyup.enter="register"
          />
          <div class="text-field-error" v-if="UsernameErr">
            <img src="@/assets/error.svg" class="errImg" />
            <div>{{ this.UsernameErr }}</div>
          </div>
        </div>
        <div class="info">
          <input
            v-model="groupName"
            type="text"
            class="adminFormTitle inputField"
            id="email"
            name="user_email"
            placeholder="单位名称*"
            required="true"
            @keyup.enter="register"
          />
          <div class="text-field-error" v-if="EmailErr">
            <img src="@/assets/error.svg" class="errImg" />
            <div>{{ this.EmailErr }}</div>
          </div>
        </div>
        <div class="info">
          <input
            v-model="passWord"
            type="password"
            class="adminFormTitle inputField"
            id="password"
            name="user_pass"
            placeholder="密码*"
            required="true"
            @blur="validatePassword"
            @keyup.enter="register"
          />
          <div class="text-field-error" v-if="PassLen">
            <img src="@/assets/error.svg" class="errImg" />
            <div>{{ this.PassLen }}</div>
          </div>
        </div>
        <div class="info">
          <input
            v-model="confirmPassword"
            type="password"
            class="adminFormTitle inputField"
            id="confirmpassword"
            name="user_pass"
            placeholder="确认密码*"
            required="true"
            @blur="comparePassword"
            @keyup.enter="register"
          />
          <div class="text-field-error" v-if="PassErr">
            <img src="@/assets/error.svg" class="errImg" />
            <div>{{ this.PassErr }}</div>
          </div>
        </div>
        <Button
          :onclick="register"
          variant="primary-cta"
          class="auth-button"
          :disabled="
            !(
              nickName &&
              userName &&
              passWord &&
              confirmPassword &&
              groupName &&
              !PassErr &&
              !UsernameErr &&
              !EmailErr &&
              passWord === confirmPassword &&
              !registered
            )
          "
          text="提交"
        />
      </div>
      <img src="@/assets/login.svg" class="auth-image" />
    </div>
  </div>
</template>

<script>
import RegisterUser from "../api/admin/authAPI.js";
import ErrorBox from "../components/ErrorBox";
import Button from "@/components/Button.vue";
export default {
  name: "register",
  components: {
    ErrorBox,
    Button
  },
  data() {
    return {
      msg: null,
      icon: null,
      nickName: "",
      groupName:"",
      userName: "",
      passWord: "",
      confirmPassword: "",
      status: false,
      UsernameErr: false,
      EmailErr: false,
      PassLen: false,
      PassErr: false,
      registered: false,
      errorIcon: "error-white",
      tickIcon: "tick-white"
    };
  },
  methods: {
    validateUsername(e) {
      if (this.userName.length > 12) {
        this.UsernameErr = "Username should be 12 characters max";
      } else {
        this.UsernameErr = false;
      }
    },
    validateEmail(e) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        this.EmailErr = false;
      } else {
        this.EmailErr = "Invalid Email";
      }
    },
    validatePassword(e) {
      if (this.passWord.length < 8) {
        this.PassLen = "Password should be atleast of 8 characters";
      } else {
        this.PassLen = false;
      }
    },
    comparePassword(e) {
      if (this.passWord !== this.confirmPassword) {
        this.PassErr = "Passwords don't match";
      } else {
        this.PassErr = false;
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async register() {
      if (
        !this.PassErr &&
        !this.UsernameErr &&
        !this.EmailErr &&
        !this.PassLen &&
        !this.registered
      ) {
        const regdata  = {
          userName:this.userName,
          nickName:this.nickName,
          groupName:this.groupName,
          passWord:this.passWord,
        }

        // console.log(regdata)
        const res = await RegisterUser.registerUser(regdata);
        console.log(res)
        if (res.data.code == 7) {
          this.$vToastify.setSettings({
            theme: "beast-error"
          });
          this.$vToastify.error(res.data.msg, "Error");
        } else {
          this.$vToastify.setSettings({
            theme: "beast-success"
          });
          this.$vToastify.success("注册成功", "Success");
          this.registered = true;
          await this.sleep(2000);
          this.$router.push("/login");
        }
      }
    }
  }
};
</script>
