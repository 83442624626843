import axiosInstance from "./axiosInstance.js";

export const getLicense = () => {
    return axiosInstance({
        url: '/gl/findGLicenseByUUIDAndMachineCode',
        method: 'get',

    })
}


export const bdGLicense = (data) => {
    return axiosInstance({
        url: '/gl/bdGLicense',
        method: 'post',
        data: data
    })
}

