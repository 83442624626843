<template>
  <div v-if="this.challDetails" class="challCard" :class="{ 'preview-challcard': isPreview }">
    <div class="challCard-firstLine">
      <div class="challCard-challName">{{ challDetails.name }}</div>
      <div class="chall-submitted">
        <div v-if="challDetails.isSolved">
          <span>已解答</span>
          <img src="@/assets/tick.svg" />
        </div>
        <!-- <div>
          <span>WriteUp已上传</span>
          <img src="@/assets/tick.svg" />
        </div> -->

      </div>

    </div>
    <div class="challCard-solves">
      {{ challDetails.points }} 分
      <!-- <span v-if="challDetails && challDetails.solves"
        >| {{ challDetails.solves.length }} 人 已完成</span
      > -->
    </div>

    <div class="challCard-tag-container">
      <span v-for="tag in challDetails.tags" :key="tag.id" class="challCard-tag">
        {{ tag }}
      </span>
    </div>
    <div class="challCard-challDesc">{{ challDetails.description }}</div>
    <div class="challCard-challDesc" v-if="challDetails.category == 'docker'">
      <el-descriptions v-if="challDetails.service_status" title="环境信息" style="width: 600px;">
        <el-descriptions-item label="端口映射">
          <el-tooltip class="box-item" effect="dark" content="http协议可点击跳转环境地址" placement="top-start"
            v-for="port in challDetails.ports_map.split(',').slice(0, -1)" :key="port">
            <el-tag size="small" @click="tiaozhuan(challDetails.url, port)" style="margin-left: 3px;">{{
              port
            }}</el-tag>
          </el-tooltip>
        </el-descriptions-item>
      </el-descriptions>
      <flip-countdown v-if="challDetails.service_status" :deadline="challDetails.count_down_time" :showDays="false"
        @timeElapsed="timeElapsedHandler" style="margin-top: 10px;"></flip-countdown>
      <div style="display: flex; justify-content: flex-start;margin-top: 10px;">
        <Button v-if="!challDetails.service_status" text="开启" variant="secondary-cta challCard-submit-buttom"
          :onclick="startEnv" style="margin-right: 10px" />
        <Button v-if="challDetails.service_status" text="关闭" variant="secondary-cta challCard-submit-buttom"
          :onclick="stopEnv" />
      </div>
    </div>
    <div class="challCard-resources" v-if="challDetails.assets !== ''">
      <p class="link-heading">
        附件:
      </p>
      <el-button type="text" @click="downFile(challDetails.assets)"> {{ filterFileName(challDetails.assets)
        }}</el-button>
    </div>
    <div v-if="!challDetails.isSolved && !isPreview" class="challCard-bottom-row">
      <div class="challCard-form">
        <input type="text" name="flag" class="challCard-form-input" id="flag-input" placeholder="FLAG{.....}"
          v-model="flag" @keyup.enter="triggerSubmit" />
        <Button text="提交" variant="secondary-cta challCard-submit-buttom"
          :disabled="flag.length === 0 || this.showSuccess || this.showFail" :onclick="submitFlag" />
      </div>

    </div>

  </div>
</template>

<script>
import { submitFlag } from "../api/userAPI";
import { StartEnv, StopEnv, GetEnv } from "../api/docker";
import Button from "@/components/Button.vue";
import LoginUser from "../api/admin/authAPI.js";
// import { CONFIG } from "@/config/config";
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "ChallCard",
  props: ["challDetails", "tag", "isPreview"],
  components: { Button, FlipCountdown },
  data() {
    return {
      // uploadURL: "/api/fileUploadAndDownload/uploadWp?ctf_uuid=" + localStorage.getItem("uuid"),
      flag: "",
      showSuccess: false,
      showFail: false,
      link: false,
      copyText: "Click to Copy",
      fileList: [],
      // headers: { 'x-token': LoginUser.getUserInfo().token }
    };
  },
  state: {
    disable: false,
    isModalVisible: false,
    assetLinks: false,
    additionalLinks: false,
  },
  methods: {
    // uploadSuccess(res) {
    //   if (res.code == 0) {
    //     this.showSuccess = true;
    //     this.$vToastify.success("上传成功！", "提示");
    //   } else {
    //     this.showFail = true;
    //     this.$vToastify.error("上传失败!", "提示");
    //   }
    // },

    filterFileName(filename) {
      return filename.substring(filename.lastIndexOf('/') + 1);

    },
    downFile(url) {
      url = "https://" + url
      // Create an invisible `a` element
      const link = document.createElement('a');
      link.href = url;

      // Extract filename and set as download attribute
      link.download = this.getFileName(url);

      // Append the link to the document body and trigger a click event
      document.body.appendChild(link);
      link.click();

      // Remove the link after triggering the download
      document.body.removeChild(link);
    },
    getFileName(url) {
      // Extract and return the filename from the URL
      return url.substring(url.lastIndexOf('/') + 1);
    },
    timeElapsedHandler() {
      var self = this;
      setTimeout(function () {
        if (self.showSuccess) {
          self.$router.go();
        } else {
          self.$emit("updateChallenges");
        }
        self.flag = "";
        self.showSuccess = false;
        self.showFail = false;
      }, 1500);
    },
    tiaozhuan(host, port) {
      let newport = port.split("-->");
      if (newport[0].split(":")[0] == 'http') {
        window.open('http://' + newport[1], "_blank");
      }

    },
    async stopEnv() {
      console.log("关闭环境");
      const ctf_uuid = localStorage.getItem("uuid");
      const res = await StopEnv({ ID: String(this.challDetails.ID), uuid: ctf_uuid });
      if (res.data.code == 0) {
        this.showSuccess = true;
        this.$vToastify.success(res.data.msg, "提示");
      } else {
        this.showFail = true;
        this.$vToastify.setSettings({
          theme: "beast-error",
        });
        this.$vToastify.error(res.data.msg, "Error");
      }
      var self = this;
      setTimeout(function () {
        if (self.showSuccess) {
          self.$router.go();
        } else {
          self.$emit("updateChallenges");
        }
        self.flag = "";
        self.showSuccess = false;
        self.showFail = false;
      }, 1500);
    },
    async startEnv() {
      const uuid = localStorage.getItem("uuid");
      const res = await StartEnv({ ID: String(this.challDetails.ID), uuid: uuid });
      if (res.data.code == 0) {
        this.showSuccess = true;
        this.$vToastify.success(res.data.msg, "提示");
      } else {
        this.showFail = true;
        this.$vToastify.setSettings({
          theme: "beast-error",
        });
        this.$vToastify.error(res.data.msg, "Error");
      }
      var self = this;
      setTimeout(function () {
        if (self.showSuccess) {
          self.$router.go();
        } else {
          self.$emit("updateChallenges");
        }
        self.flag = "";
        self.showSuccess = false;
        self.showFail = false;
      }, 1500);
    },
    // getUrl(port) {
    //   let url = CONFIG.webRoot;
    //   let ncurl = CONFIG.ncRoot;
    //   if (
    //     this.challDetails.category === "service" ||
    //     this.challDetails.category === "xinetd"
    //   ) {
    //     return `nc ${ncurl} ${port}`;
    //   }
    //   return `${CONFIG.webRoot}:${port}`;
    // },
    // getStaticUrl(asset) {
    //   let url = CONFIG.staticRoot;
    //   return `${url}${asset}`;
    // },
    getFileFromAsset(asset) {
      let paths = asset.split("/");
      return paths[paths.length - 1];
    },
    triggerSubmit() {
      if (!(this.flag.length === 0 || this.showSuccess || this.showFail)) {
        this.submitFlag();
      }
    },
    async submitFlag() {
      const ctf_uuid = localStorage.getItem("uuid");
      const res = await submitFlag({
        ID: String(this.challDetails.ID), //题目id
        flag: this.flag, //flag
        uuid: ctf_uuid, //比赛uuid
      });
      if (res.data.code == 0) {
        this.showSuccess = true;
        this.$vToastify.success(res.data.msg, "Success");
      } else {
        this.showFail = true;
        this.$vToastify.setSettings({
          theme: "beast-error",
        });
        this.$vToastify.error(res.data.msg, "Error");
      }

      var self = this;
      setTimeout(function () {
        if (self.showSuccess) {
          self.$router.go();
        } else {
          self.$emit("updateChallenges");
        }
        self.flag = "";
        self.showSuccess = false;
        self.showFail = false;
      }, 2000);
    },
    copyUrl(text) {
      navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(this.getUrl(text));
        }
      });
      (this.copyText = "Copied"),
        setTimeout(() => {
          this.copyText = "Click to Copy";
        }, 1000);
    },
    isDisabled: function () {
      let flag = document.getElementById("flag-input").value;
      if (flag != "") {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  watch: {
    challDetails() {
      this.flag = "";
    },
  }
};
</script>
