<template>
    <div class="card-container">
        <el-card shadow="hover" class="box-card">
            <el-upload class="upload-demo" drag :action="uploadURL" :on-success="uploadSuccess" :headers="headers"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过500kb</div>
            </el-upload>
        </el-card>
    </div>
</template>
<script>
import LoginUser from "../api/admin/authAPI.js";

export default {
    components: {},
    name: "wpUpload",
    data() {
        return {
            uploadURL: "/api/fileUploadAndDownload/uploadWp?ctf_uuid=" + localStorage.getItem("uuid"),
            headers: { 'x-token': LoginUser.getUserInfo().token },
        };
    },
    created() { },
    methods: {
        uploadSuccess(res) {
            if (res.code == 0) {
                this.showSuccess = true;
                this.$vToastify.success("上传成功！", "提示");
            } else {
                this.showFail = true;
                this.$vToastify.error("上传失败!", "提示");
            }
        },
    },
    computed: {},
    beforeCreate() {
        this.$store.commit("updateCurrentPage", "userLeaderboard");
    },
};
</script>

<style>
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    /* Adjust as needed */
}

.box-card {
    width: 400px;
    /* Adjust width as needed */
    padding: 20px;
    /* Add some padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    /* Optional: add rounded corners */
    background-color: #fff;
    /* Optional: card background color */
}

.upload-demo {
    text-align: center;
    /* Center text within the upload area */
}
</style>