import axiosInstance from "./axiosInstance.js";

export const getUserInfo = () => {
    return axiosInstance({
        url: '/user/getUserInfo',
        method: 'get',

    })
}


export const setSelfInfo = (data) => {
    return axiosInstance({
        url: '/user/setSelfInfo',
        method: 'put',
        data: data
    })
}
export const changePassword = (data) => {
    return axiosInstance({
        url: '/user/changePassword',
        method: 'post',
        data: data
    })
}

