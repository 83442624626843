import axiosInstance from "../axiosInstance.js";

export default {
  async getAllNotifs(uuid) {
    return await axiosInstance.get(`/nt/getNotificationList?page=1&pageSize=10&uuid=`+uuid);
  }
};
// export const getNotificationsList = () => {
//   return axiosInstance({
//     url: '/nt/getNotificationList?page=1&pageSize=10',
//     method: 'get',
//   })
// }
