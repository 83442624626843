import axiosInstance from "../axiosInstance.js";
import router from "../../router/index.js";

export default {
  async loginUser(username, password) {
    let bodyFormData = {username:username,password:password}

    const response = await axiosInstance({
      method: "post",
      url: `/base/userLogin`,
      data: bodyFormData
    });
    return response;
  },

  async loggedInUser(username, password) {
    const response = await this.loginUser(username, password);
    if (response.data.code === 0) {
      const token = response.data.data.token
      const userName = response.data.data.user.nickName;
      const useruuid = response.data.data.user.uuid
      var userInfo = {
        userName: userName,
        Score: "",
        token: token,
        login: true,
        access: true,
        role: "user",
        uuid: useruuid
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      router.push("/");
 
    } else {
      return response.data.msg;
    }
  },

  async registerUser(data) {

    const response = await axiosInstance({
      method: "post",
      url: `/base/register`,
      data: data
    });
    return response;
  },

  async resetPass(newPassword) {
    let bodyFormData = new FormData();
    bodyFormData.append("new_pass", newPassword);
    try {
      let response = await axiosInstance({
        method: "post",
        url: `/auth/reset-password`,
        data: bodyFormData
      });
      return Promise.resolve(response);
    } catch (err) {
      return err;
    }
  },

  async resetPassword(newPassword) {
    const response = await this.resetPass(newPassword);
    if (response.status === 200) {
      return true;
    }
    return false;
  },

  getUserInfo() {
    const userInfo = localStorage.getItem("userInfo");
    return JSON.parse(userInfo);
  },

  logout() {
    localStorage.removeItem("userInfo");
  }
};
