var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"adminTableContainer"},[_c('table',{staticClass:"adminTable"},[_c('thead',{staticClass:"adminTableHead"},_vm._l((_vm.tableCols),function(tableCol){return _c('th',{key:tableCol.id,staticClass:"adminTableHeader",style:(tableCol.style)},[_vm._v(" "+_vm._s(tableCol.label)+" ")])}),0),(this.userData && !this.keys)?_c('tr',{staticClass:"adminTableUserRow"},[_c('td',{staticClass:"adminTableData adminTableUserData adminTablerank"},[_vm._v(" "+_vm._s(this.userData.rank)+" ")]),_c('td',{staticClass:"adminTableData adminTableUserData adminTableusername"},[_vm._v(" "+_vm._s(this.userData.username)+" ")]),_c('td',{staticClass:"adminTableData adminTableUserData adminTablerank"},[_vm._v(" "+_vm._s(this.userData.groupname)+" ")]),_c('td',{staticClass:"adminTableData adminTableUserData adminTablerank"},[_vm._v(" "+_vm._s(this.userData.score)+" ")]),_c('td',{staticClass:"adminTableData adminTableUserData adminTablerank"},[_vm._v(" "+_vm._s(_vm.duration(this.userData.dtime))+" ")])]):_vm._e(),_c('tr',{staticClass:"adminNullRow"}),_c('tbody',{staticClass:"adminTableBody"},_vm._l((_vm.tableRows),function(row){return _c('tr',{key:row.rank,staticClass:"adminTableRow"},_vm._l((_vm.columns),function(col){return _c('td',{key:col.id,staticClass:"adminTableData",class:[
              {
                green:
                  col === 'status' &&
                  (row.status === 'Active' || row.status === 'Correct'),
                red:
                  col === 'status' &&
                  (row.status === 'Banned' || row.status === 'Incorrect'),
                bannedTableData:
                  row.status === 'Banned' && (col === 'rank' || col === 'score'),
              },
              `adminTable${col}`,
            ]},[(_vm.isColLink(col))?_c('router-link',{staticClass:"adminTableLink",attrs:{"to":_vm.getRedirectLink(col, row[col])}},[_vm._v(" "+_vm._s(row[col])+" ")]):_c('span',[_vm._v(" "+_vm._s(row[col])+" ")])],1)}),0)}),0)])]),_c('div',{staticClass:"paginateContainer"},[_c('div',{staticClass:"pagination"},[(_vm.pageCount - 1)?_c('paginate',{attrs:{"page-count":_vm.pageCount,"click-handler":_vm.pageChangeHandler,"page-range":3,"margin-pages":2,"prev-text":'Previous',"next-text":'Next',"container-class":'pagination',"page-link-class":'pageItem',"prev-link-class":'prevItem',"next-link-class":'nextItem',"break-view-link-class":'breakItemlink-',"no-li-surround":true,"hide-prev-next":true},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),(_vm.pageCount - 1)?_c('div',{staticClass:"jumpPage"},[_c('span',[_vm._v("Jump to")]),_c('form',{staticClass:"form",on:{"submit":_vm.changePage}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jumpPage),expression:"jumpPage"}],staticClass:"pageInput",attrs:{"type":"number","min":1,"max":_vm.pageCount},domProps:{"value":(_vm.jumpPage)},on:{"input":function($event){if($event.target.composing)return;_vm.jumpPage=$event.target.value}}})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }